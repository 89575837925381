import React, { useEffect } from "react";

// Components
import Header from "../components/header/Header";
import MobileHeader from "../components/mobileHeader/MobileHeader";
import Footer from "../components/footer/Footer";

// Material-UI
import useStyles from "./pp-tcs-style";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Hidden from "@mui/material/Hidden";

var mixpanel = require("mixpanel-browser");

function PrivacyPolicy() {
  const classes = useStyles();

  useEffect(() => {
    mixpanel.landing.track('Privacy Policy Page');
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Hidden mdDown>
          <Header />
        </Hidden>
        <Hidden mdUp>
          <MobileHeader />
        </Hidden>
      </div>
      <div className={classes.main}>
        <Typography variant="h2" align="center" className={classes.title}>
          ApTap Privacy &amp; Cookie Policy
        </Typography>
        <Typography variant="h3" align="center" className={classes.subtitle}>
          You are viewing our current policy; last updated January 2025
        </Typography>

        <Typography variant="body1" className={classes.text}>
          ApTap LTD is committed to protecting and respecting your privacy.
          <br></br>
          <br></br>
          Personal data, or personal information, means any information about a
          living individual from which that person can be identified. It does
          not include data where the identity has been removed and cannot be
          traced back to any identifiable individual (anonymous data). This
          policy sets out the basis on which any personal data we collect from
          you, or that you provide to us or we obtain about you, will be
          processed, stored and disclosed by us. Please read the following
          carefully to understand our practices regarding your personal data and
          how we will treat it. By visiting
          <a href="https://www.aptap.co.uk">www.aptap.co.uk</a> (the “
          <b>Site</b>”) or using ApTap’s mobile application (the “<b>App</b>”)
          or using any services offered through or associated with our Site or
          App (the “<b>Services</b>”), your personal data will be processed in
          accordance with the practices described in this policy.
          <br></br>
          <br></br>
          The Site, App and Services are provided by ApTap Limited (company no.
          11171584) of 124 City Road, London, England, EC1V 2NX
          (our “<b>Company Address</b>”). See our other contact details above.
          <br></br>
          <br></br>
          ApTap LTD is the controller, as defined in the General Data Protection
          Regulation 2016 (“<b>GDPR</b>”), responsible for your personal data.
          ApTap LTD has a Data Protection Officer (“<b>DPO</b>”) who is
          responsible for matters relating to privacy and data protection. Our
          DPO is currently Rachel Gentry, who can be reached at{" "}
          <a href="mailto: data@aptap.co.uk">data@aptap.co.uk</a>. ApTap LTD is
          registered with the ICO under reference number ZB826728
          <br></br>
          <br></br>
          The Site and App may contain links to and from the websites of our
          partner networks, advertisers and affiliates or to websites shared by
          other users. If you follow a link to any of these websites, please
          note that these websites have their own privacy policies and that we
          do not accept any responsibility or liability for these policies.
          Please check these policies before you submit any personal data to
          these websites.
        </Typography>

        <Typography variant="body1" className={classes.sectionTitle}>
          1. Data We Collect About You
        </Typography>
        <Typography variant="body1" className={classes.text}>
          We collect and process personal information so we can provide our
          Services to you. It’s important that the personal data we hold about
          you is accurate and current. Please keep us informed if your personal
          data changes during your relationship with us; you can make changes
          via the profile page or by getting in touch with us.
          <br></br>
          <br></br>
          The data we collect and process about you is explained in the sections
          below.
        </Typography>

        <Typography variant="body1" className={classes.sectionSubTitle}>
          1.1 Data You Give Us
        </Typography>
        <Typography variant="body1" className={classes.text}>
          You give us your personal data by filling in forms on our Site or App
          or by corresponding with us by telephone, e-mail or otherwise. This
          includes information you provide when you register to use the Site or
          App, subscribe to our marketing database, subscribe to our Services,
          search for a service, confirm a deal or quote through our Site or App
          or report a problem with either of them.
          <br></br>
          <br></br>
          To access our Services, we will ask you to provide your name, surname
          and email address via our Site so we can send you a link to download
          our App. When you register to use the App, we collect your name, email
          address, address, birthday, phone number and the password that you
          choose.
          <br></br>
          <br></br>
          In order to use the Services provided by the App, you may need to
          connect a bank account (through our third-party provider, TrueLayer),
          which requires you to provide your bank account information (including
          any security questions and mobile number for SMS-code verification)
          and we and TrueLayer will be able to see your transactions through
          that account and details of cards linked to it. You will also need to
          provide your mailing address, postcode and usage information in order
          for third-party providers to generate a quote for you.
          <br></br>
          <br></br>
          If the bank account you connect to our Services is a joint bank
          account, you must have checked with the other joint account holder(s)
          that they are happy for you to connect that bank account and for us to
          see the information about it and them. You must also let them know
          about this privacy policy and how we will use personal information.
          <br></br>
          <br></br>
          If you want to go ahead and sign up to any deals or quotes that we
          find for you, we will need some other information in order for the
          third-party provider to sign you up; this varies from provider to
          provider but will usually include your address history, date of birth
          and bank account information. You will also need to confirm the
          details of any joint account holder who will be signed up with that
          third-party provider as well as you and have confirmed with them that
          they are happy to proceed.
          <br></br>
          <br></br>
          Within the App, you may also choose to provide your other telephone
          numbers, sex and date of birth. This information is optional but
          providing it to us helps us build more accurate user profiles and
          predict trends. This in turn enables us to offer better tailored deals
          and quotes and improve the Services.
        </Typography>

        <Typography variant="body1" className={classes.sectionSubTitle}>
          1.2 Data We Automatically Collect
        </Typography>
        <Typography variant="body1" className={classes.text}>
          Each time you visit or use our Site or App, we automatically collect
          the following information:
        </Typography>
        <br></br>
        <br></br>
        <ul>
          <li>
            <Typography variant="body1" className={classes.text}>
              <b>technical information</b>, including the Internet protocol (IP)
              address used to connect your computer or device to the Internet,
              your login information, browser type and version, time zone
              setting, location, network data, browser plug-in types and
              versions, languages, operating system and platform;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              <b>information about your visit</b>, including the full Uniform
              Resource Locators (URL) clickstream to, through and from the Site
              or App (including date and time); pages you viewed or searched
              for; page response times, download errors, length of visits to
              certain pages, page interaction information (such as scrolling,
              clicks, and mouse-overs), and methods used to browse away from the
              page and any phone number used to call our customer service
              number.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" className={classes.text}>
          We use this information as statistical data about your browsing
          actions and patterns, for system administration, and to evaluate,
          provide, protect or improve our Services (including by developing new
          products and services). Because we collect, use and share this
          information in the aggregate, it does not identify any individual.
        </Typography>

        <Typography variant="body1" className={classes.sectionSubTitle}>
          1.3 Data We Receive from Third Parties
        </Typography>
        <Typography variant="body1" className={classes.text}>
          We receive information from third parties when you use our Services.
          These third parties decide how and why they use that data when you
          provided it to them originally – meaning they are a controller of your
          personal data. Where this is the case, we are not responsible for the
          third party’s use of your personal data and you should review their
          privacy policies to find out more about how and why they use your
          personal data. Once we receive information from them however, we
          become an independent controller of that information as well but for
          the purposes set out in this policy.
          <br></br>
          <br></br>
          The third parties that we receive your data from include:
          <br></br>
          <br></br>
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" className={classes.text}>
              any budgeting tools, banks or service providers you integrate with
              our Services. These third parties may restrict how or why or for
              how long we can use your personal data. These third parties have
              access to data we share with their platforms; and
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              where we receive information from your bank, we use third parties
              such as TrueLayer to facilitate the transfer of this data to us.
              You can view TrueLayer’s privacy notice here:{" "}
              <a href="https://truelayer.com/privacy/">
                https://truelayer.com/privacy/
              </a>
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" className={classes.sectionTitle}>
          2. How We Use Your Data
        </Typography>
        <Typography variant="body1" className={classes.text}>
          We use your data to provide our Site, App and Services in the
          following ways:
        </Typography>

        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            style={{ tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  <b>How might we use your data?</b>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  <b>
                    What lawful basis do you have to use my personal data in
                    this way?
                  </b>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  <b>Why do you use my personal data in this way?</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We may share your personal data with partners or suppliers and
                  third party product suppliers so that they can return deals
                  and quotes.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  It is necessary for us to process your personal data to
                  provide our Services to you, in line with our{" "}
                  <a
                    onClick={() => {
                      mixpanel.track("Terms and Conditions Page", {
                        navigatedFrom: window.location.href,
                      });
                    }}
                    href="/terms-and-conditions"
                  >
                    terms and conditions
                  </a>
                  .
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  Without doing so we would not be able to offer you our
                  comparison and switching capabilities.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  If you opt-in to a deal or to receive communications from a
                  third party product supplier.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We will only share your details with those suppliers with your
                  explicit <b>consent</b>. If you decide to go ahead with a
                  deal, there may be certain information that you must provide
                  in order for them to put in place a <b>contract</b> with you
                  for their products or services.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  This is so that the supplier may contact you regarding the
                  deal or quote and/or to sign you up to a new deal.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  To send service communications.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  This is necessary to provide our Services to you in line with
                  our{" "}
                  <a
                    onClick={() => {
                      mixpanel.track("Terms and Conditions Page", {
                        navigatedFrom: window.location.href,
                      });
                    }}
                    href="/terms-and-conditions"
                  >
                    terms and conditions
                  </a>
                  .
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  Without doing so we would not be able to offer you our
                  comparison and switching capabilities.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  To verify that a sale or transaction has been completed.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We have a <b>legitimate interest</b> to process personal data
                  in order to confirm a transaction has been completed and for
                  audit purposes.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  This keeps our Services free, as when we validate that the
                  sale has completed, we make a commission from the third party
                  product supplier.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  To detect or prevent financial crime or fraud.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  This is in our <b>legitimate interest</b> to prevent financial
                  crime or fraud. We may also have a <b>legal obligation</b> to
                  provide information to police forces and other regulatory
                  bodies.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We take our responsibility to tackle and mitigate financial
                  crime very seriously. It is in our legitimate interest to
                  avoid financial crimes, which may negatively impact someone.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  To personalise or tailor the services to you.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We have a <b>legitimate interest</b> in improving your user
                  experience and offer an excellent level of service.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  This helps us better understand your needs and wants to then
                  improve our Services and make deals you see more pertinent.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  To gather feedback and reviews for customer research purposes.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We have a <b>legitimate interest</b> in improving your user
                  experience and offering an excellent level of service.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  This helps us better understand your needs and wants to then
                  improve our services and make deals you see more pertinent.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  For general marketing communications from us.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We will only process your personal data with your explicit{" "}
                  <b>consent</b> regarding direct marketing communications.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  If you wish to receive calls, mail or email updates from us at
                  ApTap, where we keep you up to date and in the know.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  To comply with legal and regulatory obligations.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  This is necessary so we can comply with any{" "}
                  <b>legal obligations</b> to which we are subject.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  The space in which we operate is highly regulated and designed
                  to protect your data. We may have to process data in order to
                  comply legally.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  To measure or understand the effectiveness of advertising we
                  serve to you and others, deliver relevant advertising to you
                  and make suggestions and recommendations to you and other
                  users of the Site and App about goods or services that may
                  interest you or them.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We have a <b>legitimate interest</b> in improving your user
                  experience and offering an excellent level of service.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  This helps us better understand your needs and wants to then
                  improve our services and make deals you see more pertinent.
                  Where information is gathered using <b>cookies</b>, you can
                  find out about this in the &quot;Cookies&quot; section below
                  as well as how to reject the use of certain cookies.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  To administer the Site and App and for internal operations,
                  including troubleshooting, data analysis, testing, research,
                  statistical and survey purposes.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We have a <b>legitimate interest</b> in making sure our Site
                  and App operates smoothly and in a user-friendly way.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We want to make sure you enjoy using the Site and App and
                  it&#39;s easy to move about and use the Services.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  As part of our efforts to keep the Site and App safe and
                  secure, e.g. by conducting analysis required to detect
                  malicious data and understand how this may affect your IT
                  system.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  We have a <b>legitimate interest</b> in keeping our Site and
                  App secure and the data that is stored within it.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  The space in which we operate is highly regulated and designed
                  to protect your data. We need to make sure we comply with
                  those regulations. We also need to make sure your data
                  isn&#39;t available to anyone who shouldn&#39;t have it.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="body1" className={classes.sectionTitle}>
          3. How We Secure Your Data
        </Typography>
        <Typography variant="body1" className={classes.text}>
          All of your personal information is protected, and we have put in
          place appropriate physical, electronic, and management procedures to
          safeguard and secure the data we collect. Your information is stored
          on secure cloud databases, internal servers, and on third party
          software.
          <br></br>
          <br></br>
          Your information is only accessible by employees, agents, and
          contractors who have authorised access rights to such information and
          who have a business need to know. It&#39;s also accessible to the
          third party service providers we work with to the extent necessary to
          provide our Services. They will only process your personal data on our
          instructions and they are subject to a duty of confidentiality.
          <br></br>
          <br></br>
          All of your payment information is encrypted using SSL technology and
          banking standards used by Open Banking.
          <br></br>
          <br></br>
          Unfortunately, the transmission of information via the internet may
          not be completely secure. Although we do our best to protect your
          personal data, we cannot guarantee the security of your data
          transmitted to our Site or App; any transmission is at your own risk.
          Once we have received your information, we use strict procedures and
          the security features described above to try to prevent unauthorised
          access.
        </Typography>

        <Typography variant="body1" className={classes.sectionTitle}>
          4. How Long We Store Your Data
        </Typography>
        <Typography variant="body1" className={classes.text}>
          We only keep your personal information for as long as it’s necessary
          for the purpose for which we collected it.
          <br></br>
          <br></br>
          We typically delete the majority your personal information when you
          delete your account by emailing{" "}
          <a href="mailto: contact@aptap.co.uk">contact@aptap.co.uk</a> or by
          deleting the account within the App. We may also delete your data if
          the third party service provider that you have integrated with ApTap
          requires us to delete it sooner (see the ‘Data we Receive from Third
          Parties’ section above for further information).
          <br></br>
          <br></br>
          We do keep some information about products you switch to using our
          Services, and the commission we receive for it, for our tax and
          accounting purposes.
          <br></br>
          <br></br>
          If you ask us to stop sending marketing material to you or profiling
          you, we will keep your details on a suppression list to make sure you
          don&#39;t receive anything from us.
          <br></br>
          <br></br>
          We also need to keep some of your information in archived form in
          order to defend our legal rights. This may be for the period during
          which legal claims can be made under applicable law. In the UK this is
          six years for contractual claims. We have policies and procedures in
          place to make sure that we delete information no longer needed for any
          of these purposes.
          <br></br>
          <br></br>
          We create anonymous datasets from the information gathered through the
          Site and App so that we can analyse trends and research how to improve
          our Services and the deals that users see. No-one can be identified
          from these datasets.
          <br></br>
          <br></br>
          Other controllers who receive your information in your use of the
          Services will retain your information in accordance with their own
          privacy policies.
        </Typography>

        <Typography variant="body1" className={classes.sectionTitle}>
          5. Disclosure to Third Parties
        </Typography>
        <Typography variant="body1" className={classes.text}>
          We require all third parties that we work with to respect the security
          of your personal data and to treat it in accordance with the law.
          <br></br>
          <br></br>
          Some of the third parties that we disclose your data to when you use
          our Services decide how and why they use that data – meaning they are
          a controller of your personal data. Where this is the case, we are not
          responsible for the third party’s use of your personal data and you
          should review their privacy policies to find out more about how and
          why they use your personal data. We set out below a list of such third
          party controllers and provide a link to their websites where you can
          find their privacy notice, although we may from time-to-time notify
          you of other third parties that use your personal data as a
          controller. We always aim to make sure that we only work with
          trustworthy partners.
          <br></br>
          <br></br>
        </Typography>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            style={{ tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  <b>Name of third party controller</b>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  <b>Reason for the third party receiving your data</b>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  <b>Link to third party website</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  TrueLayer
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  To confirm who you are and then to access the bank accounts
                  you want us to analyse.
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={classes.tablecell}
                >
                  <a href="https://truelayer.com/privacy/">
                    https://truelayer.com/privacy/
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body1" className={classes.text}>
          We also share your personal data with third parties that provide
          applications/functionality, data processing, IT and other services to
          us. These third parties act under our direction and control when they
          use your personal data - meaning they are our processors and use your
          personal data in accordance with this policy. Examples of such third
          parties include MongoDB for database sorting services and Amazon AWS
          for data storage and protection. For certain services, TrueLayer also
          acts on our behalf as well (they are a controller for other services).
          We ensure that our contracts with these third parties protect your
          data in accordance with data protection laws.
          <br></br>
          <br></br>
          The only other circumstances under which we would share your personal
          data are:
          <br></br>
          <br></br>
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" className={classes.text}>
              If the third party is a member of our group (which means any
              subsidiaries or ultimate holding company and its subsidiaries, as
              defined in section 1159 of the UK Companies Act 2006).
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              In the event that we sell or buy any business or assets, in which
              case we may disclose your personal data to the prospective seller
              or buyer of such business or assets.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              If ApTap Ltd or substantially all of its assets are acquired by a
              third party, in which case personal data will be one of the
              transferred assets and the purchaser will be permitted to use the
              data for the purposes for which it was originally collected by us.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              If we’re under a duty to disclose or share your personal data in
              order to comply with any legal obligation, to enforce or apply our
              Terms &amp; Conditions and other agreements, or to protect the
              rights, property, or safety of ApTap Ltd, our customers, or others
              (including exchanging information with other companies and
              organisations for the purposes of fraud protection and credit risk
              reduction).
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              If we are otherwise permitted to disclosure it under data
              protection law.
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" className={classes.sectionTitle}>
          6. International Transfers
        </Typography>
        <Typography variant="body1" className={classes.text}>
          We do not use service providers based outside UK or the European
          Economic Area (<b>EEA</b>), and the data that we collect from you will
          only be transferred to, and stored at, a destination inside the UK or
          EEA. By submitting your personal data, you agree to our transfer,
          storage and processing methods. In the event that data must be passed
          outside of the EEA, we will take all steps necessary to ensure that
          your data is treated as securely as it would be within the UK and EEA
          and under the GDPR. Such steps may include our entering into contracts
          with any third parties we engage and the use of EU Commission-approved
          Model Contractual Clauses.
          <br></br>
          <br></br>
          You can obtain more details of the protection given to your personal
          data when it is transferred outside of the EEA (including a copy of
          the standard data protection clauses which we have entered into with
          recipients of your personal data) by contacting us as described in the
          Contact paragraph below.
        </Typography>

        <Typography variant="body1" className={classes.sectionTitle}>
          7. Marketing
        </Typography>
        <Typography variant="body1" className={classes.text}>
          We will not send you any unsolicited marketing or spam and will ensure
          that we fully protect your rights and comply with our obligations
          under applicable data protection laws. If you are an existing
          customer, we will only contact you by email, SMS, by phone or by mail,
          with information about services similar to those which were the
          subject of a previous enquiry by you and where you have not initially
          or at any other time opted out of electronic marketing. Where we
          permit third parties to use your data to provide our Services, they
          will send you marketing materials by electronic means only if you have
          expressly consented to this.
          <br></br>
          <br></br>
          You have the right to ask us not to process your personal data for
          marketing purposes. We will get your express opt-in consent before we
          use your data for such purposes or share your personal data with any
          third parties for such purposes, but you can exercise your right to
          prevent such processing by contacting us at the Company Address, via
          email at <a href="mailto: contact@aptap.co.uk">contact@aptap.co.uk</a>
          , or by unsubscribing using the links contained in the marketing
          emails.
        </Typography>

        <Typography variant="body1" className={classes.sectionTitle}>
          8. Your Rights
        </Typography>
        <Typography variant="body1" className={classes.text}>
          Under the GDPR, you have the right to:
        </Typography>
        <br></br>
        <br></br>
        <ul>
          <li>
            <Typography variant="body1" className={classes.text}>
              Withdraw your consent to the processing of your personal data at
              any time. Please note, however, that we may still be entitled to
              process your personal data if we have another legitimate reason
              for doing so (such as to comply with a legal obligation).
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              Be informed of what data we hold and the purpose for processing
              the data, as a whole or in parts. (This policy tells you what we
              do).
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              Be forgotten and, in some circumstances, have your data erased by
              ourselves and our affiliates (although this is not an absolute
              right and there may be circumstances where you ask us to erase
              your personal data but we are legally entitled to retain it).
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              Correct or supplement any information we hold about you that is
              incorrect or incomplete. (You can do a lot of this through the
              profile page in the Site and App).
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              Restrict processing of the information we hold about you (for
              example, so that inaccuracies may be corrected—but again, there
              may be circumstances where you ask us to restrict processing of
              your personal data but we are legally entitled to refuse that
              request).
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              Object to the processing of your data.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              Obtain your data in a portable manner and reuse the information we
              hold about you.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              Challenge any data we use for the purposes of automated
              decision-making and profiling (in certain circumstances—as above,
              there may be circumstances where you ask us to restrict our
              processing of your personal data but we are legally entitled to
              refuse that request).
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              Complain to a supervisory authority (e.g. the Information
              Commissioner’s Office (ICO) in the UK) if you think any of your
              rights have been infringed by us. (We would, however, appreciate
              the chance to address your concerns, so please contact us prior to
              taking this step).
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" className={classes.text}>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights) unless your request is clearly
          unfounded, repetitive or excessive. Alternatively, we may refuse to
          comply with your request in these circumstances.
          <br></br>
          <br></br>
          You can read about all of your rights here:{" "}
          <a href="https://ico.org.uk/your-data-matters/">
            https://ico.org.uk/your-data-matters/
          </a>
          .<br></br>
          <br></br>
          You may revoke your consent for us to use your personal data as
          described in this Privacy Policy at any time by emailing us at{" "}
          <a href="mailto: contact@aptap.co.uk">contact@aptap.co.uk</a>, and we
          will delete your data from our systems. To enforce any of the above
          rights, please contact us at our Company Address or via email at{" "}
          <a href="mailto: contact@aptap.co.uk">contact@aptap.co.uk</a>.
          <br></br>
          <br></br>
          We will notify you and any applicable regulator of a breach of your
          personal data when we are legally required to do so.
        </Typography>

        <Typography variant="body1" className={classes.sectionTitle}>
          9. Cookies
        </Typography>
        <Typography variant="body1" className={classes.text}>
          A cookie is a small file of letters and numbers that we store on your
          browser. Cookies contain information that is transferred to your
          computer&#39;s hard drive (or the hard drive of another relevant
          device). We use cookies to distinguish you from other users on the
          Site, to tailor your experience to your preferences, and to help us
          improve the Site.
          <br></br>
          <br></br>
          Some of the cookies we use are essential for the Site to operate. If
          you use your browser settings to block all cookies (including
          essential cookies), you may not be able to access all or parts of our
          Site.
          <br></br>
          <br></br>
          Before any non-essential or third party cookies are placed on your
          device, you will be shown a pop-up message requesting your consent to
          setting those cookies. By default, most internet browsers accept
          cookies, but you can choose to enable or disable some or all cookies
          via the settings on your internet browser or our pop-up message. Most
          internet browsers also enable you to choose whether you wish to
          disable all cookies or only third party cookies. For further details,
          please consult the help menu in your internet browser or visit
          www.aboutcookies.org or www.allaboutcookies.org.
          <br></br>
          <br></br>
          We use the following cookies:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" className={classes.text}>
              <b>Strictly necessary cookies</b>. These cookies are required to
              save your session and to carry out other activities that are
              strictly necessary for the operation of the Services. They
              include, by way of general example, cookies that enable you to log
              into secure areas of the Site or App, remember who you are while
              using the Site or App, or make use of the Services. These cookies
              are session cookies, which means they’re temporary and will expire
              when you close your browser.
            </Typography>
            <ul>
              <li style={{ marginLeft: "20px" }}>
                <Typography variant="body1" className={classes.text}>
                  We use Login (a cookie we built ourselves)
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              <b>Analytical/performance cookies</b>. These cookies allow us to
              recognise and count the number of visitors and to see how visitors
              move around the Site or App when they’re using it. These cookies
              help us improve the way the Site and App work by, for example,
              ensuring that users are finding what they’re looking for easily.
            </Typography>
            <ul>
              <li style={{ marginLeft: "20px" }}>
                <Typography variant="body1" className={classes.text}>
                  We use Google Analytics
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant="body1" className={classes.text}>
              <b>Social Media cookies</b>. These cookies work together with
              social media plug-ins. For example, when we embed photos, video
              and other content from social media websites, the embedded pages
              contain cookies from these websites. Similarly, if you choose to
              share our content on social media, a cookie may be set by the
              service you have chosen to share content through e.g. Instagram,
              Facebook, LinkedIn or Twitter.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" className={classes.text}>
          As well as being able to reject the use of non-essential and third
          party cookies, you have the right to object to automated profiling
          (this is where the data collected about you via cookies and other
          technology across our site and app and third party sites is combined
          to produce a profile of you and deliver personalised content based on
          this profile). To enforce this right please contact us at{" "}
          <a href="mailto: contact@aptap.co.uk">contact@aptap.co.uk</a>.
        </Typography>

        <Typography variant="body1" className={classes.sectionTitle}>
          10. Changes to Our Privacy &amp; Cookie Policies
        </Typography>
        <Typography variant="body1" className={classes.text}>
          Any changes we may make to our Privacy &amp; Cookie Policies in the
          future will be posted on this page and, where appropriate, notified to
          you by email. You will be deemed to have accepted the terms of the
          updated Privacy &amp; Cookie Policies on your first use of the Site or
          App following the alterations. Please check back frequently to see any
          updates or changes to our Privacy &amp; Cookie Policies.
        </Typography>

        <Typography variant="body1" className={classes.sectionTitle}>
          11. Contact
        </Typography>
        <Typography variant="body1" className={classes.text}>
          Questions, comments and requests regarding this Privacy &amp; Cookie
          Policy are welcomed and should be addressed to our Company Address or
          to our email at{" "}
          <a href="mailto: contact@aptap.co.uk">contact@aptap.co.uk</a>.
          <br></br>
          <br></br>
          As stated, we may communicate with you via our website and via email,
          SMS or phone. Please understand that you will need internet access or
          phone service to receive these communications. All correspondence from
          ApTap will be sent in English.
        </Typography>
      </div>
      <div className={classes.bottom}>
        <Footer />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
